import config from '@/config'
import acquireAzToken from 'mbi.luna.apps.aad/dist/frontend/acquireAzToken'
import { PublicClientApplication } from '@azure/msal-browser'

let hasSignedInWithSSO = false

export const getAuthToken = async (options = {}, force = false) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  if (params.embedded === 'true' && !hasSignedInWithSSO) {
    // Sign in silently, required for seamless authentication in embedded mode
    const msal = new PublicClientApplication(config.aad)
    await msal.initialize()
    const loginResponse = await msal.ssoSilent({
      scopes: [...config.aad.api.scopes],
      loginHint: params.User,
    })

    // Once signed in using ssoSilent, we can use the standard acquireAzToken method which uses the token cache
    hasSignedInWithSSO = true
    return `Bearer ${loginResponse.accessToken}`
  }

  if (config.authMode === 'azure' || force) {
    // Get username from query params

    const defaultOptions = {
      reloadOnNewToken: true,
      acquireTokenRedirect: true,
      username: params.User,
    }

    const token = await acquireAzToken(config.aad, { ...defaultOptions, ...options })
    return token.bearerToken
  }
}

export default getAuthToken
