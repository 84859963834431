<template>
  <div
    :data-activityUniqueId="itemId"
    :data-itemType="itemType"
    :data-cy="`timeline-${itemType}-item`"
  >
    <span
      v-if="ability.can('view', 'context:classificationStatus') && hasStatusCode"
      class="status-circle"
      :class="rttIsIllogical && 'illogical'"
    >
      {{ getStatusCode() }}
    </span>
    <n-icon
      v-if="icon && itemType !== 'activity'"
      :icon="['fal', icon]"
      :class="`timeline-${itemType}-icon`"
    />
    <div v-if="uiStore.state.visShowDetail" :class="itemType === 'activity' ? 'pr-1' : ''">
      {{ title }}
    </div>
    <div v-if="statusIconMapping" class="icon status-icon" :class="statusIconClassName || ''">
      <n-icon :icon="[iconType, statusIconMapping]" />
    </div>
  </div>
</template>

<script setup>
import NIcon from '@/components/ui/n-icon/n-icon.vue'
import useAbility from '@/services/ability.js'
import useUiStore from '@/store/modules/ui'
import useTimelineStore from '@/store/modules/timeline'
import { ref } from 'vue'

const props = defineProps({
  itemType: {
    type: String,
    default: '',
  },
  itemId: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  iconType: {
    type: String,
    default: 'fal',
  },
  title: {
    type: String,
    default: '',
  },
  statusIconMapping: {
    type: String,
    default: '',
  },
  statusIconClassName: {
    type: String,
    default: '',
  },
  validationStatus: {
    type: String,
    default: null,
  },
  start: {
    type: String,
    default: null,
  },
})

const ability = useAbility()
const uiStore = useUiStore()
const timelineStore = useTimelineStore()
const hasStatusCode = ref(true)
const rttIsIllogical = ref(false)

const getStatusCode = () => {
  const rttItem = timelineStore.rttData.find(
    (rtt) => rtt.start === props.start && rtt.type === 'RTT Status',
  )
  if (!rttItem) {
    hasStatusCode.value = false
    return ''
  }
  if (rttItem.isIllogical === true) {
    rttIsIllogical.value = true
  }
  const code =
    !uiStore.state.visShowLocalRTTCodes || !rttItem.statusLocal
      ? rttItem.statusNational
      : rttItem.statusLocal

  hasStatusCode.value = true
  return code
}
</script>
