<template>
  <span class="document-content-tag" data-cy="document-content-tag">
    <!-- Classifier Icon, Underline & popover -->
    <span v-for="classification in classifications" :key="classification._id">
      <classification-popover
        v-if="!classification.hideInDocumentReader"
        :id="`sentence-icon-classification-id-${classification._id}`"
        data-cy="matched-sentence-icon"
        :classification="classification"
        :file-id="fileId"
        :file-number="fileNumber"
        :tag="tag"
        :background-color="backgroundColor"
        :text="text"
        :rova-version="rovaVersion"
        style="display: inline-block"
      />
      <!-- Classifier Text -->
    </span>
    <span
      :style="anyClassificationVisible ? { 'border-bottom': `1px solid` } : {}"
      :class="anyClassificationVisible ? [`has-border-${textColour}`] : []"
      v-html="text"
    >
    </span>
  </span>
</template>

<script setup>
import ClassificationPopover from '@/components/document-nlp/classification-popover/classification-popover.vue'
import { defineProps, onMounted, ref, computed } from 'vue'
import useConfigStore from '@/store/modules/config'

const props = defineProps({
  classifications: {
    type: Array,
    required: true,
  },
  fileId: {
    type: String,
    default: null,
  },
  fileNumber: {
    type: String,
    required: true,
    default: null,
  },
  tag: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'tag',
  },
  backgroundColor: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    required: true,
  },
  rovaVersion: {
    type: String,
    default: null,
  },
  textColour: {
    type: String,
    required: true,
  },
})

const enableDocumentClassification = ref(false)

const anyClassificationVisible = computed(() => {
  return props.classifications.some((classification) => !classification.hide)
})

onMounted(async () => {
  enableDocumentClassification.value = useConfigStore().getConfigByKey(
    'application-config.features.enableDocumentClassification',
  )

  if (props.type === 'tag') {
    enableDocumentClassification.value = false
  }
})
</script>

<style scoped lang="less">
[data-entity] {
  font-weight: normal;
  padding: 0.2em 0.3em;
  margin: 0 0.25em;
  line-height: 11px;
  display: inline-block;
  border-radius: 0.25em;
  background: #a6e22d;
  &:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: attr(data-entity);
    font-size: 0.7em;
    line-height: 1;
    padding: 0.35em 0.35em;
    border-radius: 0.35em;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 0.5rem;
    background: #fff;
    font-weight: 700;
  }
}
</style>
<style lang="less">
.document-content-tag {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.document-tags .fa-stack,
.document-accordion--header .fa-stack {
  width: auto;
}

.document-tags {
  &.ant-btn {
    height: 30px;
    line-height: 22px;
    margin-right: 5px;
  }

  & .main-icon {
    font-size: 18px;
  }
}
</style>
