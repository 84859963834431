export default () => {
  const checkedHandler = (event) => {
    let checked = event.target.checked

    let colId = this.params.column.colId

    this.params.node.setDataValue(colId, checked)
  }
  const template = `
    <input 
      type="checkbox" 
      @click="checkedHandler($event)"
      :checked="params.value"
    />
`
  return {
    template,
    checkedHandler,
  }
}
