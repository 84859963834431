<template>
  <span :class="`icon-${icon[1]} fa-stack'}`">
    <span v-if="text" class="text-icon">
      {{ text }}
    </span>
    <i v-else :class="[cssClass, iconClass]" class="fa-icon main-icon"></i>
  </span>
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  icon: {
    type: Array,
    required: false,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  cssClass: {
    type: String,
    default: null,
  },
})

const iconClass = ref()
const text = ref('')

if (props.icon[1].includes('alphabet')) {
  iconClass.value = null
  text.value = props.icon[1].split('-')[1]
} else {
  iconClass.value = `${props.icon[0]} fa-${props.icon[1]}`
}
</script>
<style lang="css">
.text-icon {
  font-size: 1.3em;
  font-weight: bold;
}
.vis-item-content .fa-stack {
  padding: 0 4px;
}
</style>
