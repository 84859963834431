<template>
  <div data-cy="document-panel" class="document-panel n-pt-05">
    <div v-if="documentStore.documentModels.length <= 0" class="n-txt--center no-documents-text">
      {{ $t('no-documents-loaded') }}
    </div>
    <a-collapse
      v-model:activeKey="activeKey"
      :bordered="false"
      :accordion="false"
      class="document-accordion"
      expand-icon-position="left"
      default-active-key="0"
    >
      <template #expandIcon="{ isActive }">
        <caret-right-outlined :rotate="isActive ? 90 : 0" />
      </template>
      <a-collapse-panel
        v-for="(doc, docIndex) of documentStore.documentModels"
        :key="getKey(docIndex)"
      >
        <document-reader
          class="document-item"
          data-cy="document-item"
          :model="doc"
          :preview-type="documentStore.selectedDocumentPanelView"
          :enabled-tags="enabledTags"
        />
        <template #extra>
          <div class="columns is-flex is-justify-content-space-between">
            <div
              data-cy="document-header"
              class="n-flex-grow document-accordion--header n-mr-2 n-txt--left"
            >
              <div class="column header-tags-column">
                <n-tag
                  data-cy="document-id-tag"
                  icon="key"
                  label="Document ID"
                  :value="getSourceDocumentId(doc)"
                />
                <n-tag
                  data-cy="document-date-tag"
                  icon="calendar"
                  label="Date"
                  :value="getFriendlyDate(doc.FileDate || doc.CreationDateTime)"
                />
                <n-tag data-cy="document-type-tag" label="Type" :value="doc.LetterType" />
                <n-tag data-cy="document-title-tag" label="Doc Title" :value="doc.DocumentTitle" />
                <n-tag
                  data-cy="document-category-tag"
                  label="Doc Category"
                  :value="doc.DocumentCategory"
                />
                <n-tag
                  data-cy="document-rova-version-tag"
                  label="Rova"
                  :value="getRovaVersion(doc)"
                />
                <template v-if="doc.ClassificationStatus === 'classified'">
                  <div
                    v-for="(match, index) of getClassificationGroups(doc.classifications)"
                    data-cy="document-header-classification"
                    :key="index"
                    :class="{ 'mr-1': match.result !== 'negative' }"
                  >
                    <template
                      v-if="
                        match.result !== 'negative' && !match.hideInDocumentReader && match.sentence
                      "
                    >
                      <n-icon
                        v-for="(classifier, cIndex) of match.classifiers"
                        :key="`classifier-${cIndex}`"
                        class="mr-1"
                        :icon="['fal', documentNLPMixin.getClassificationStatusIcon(classifier)]"
                        :class="[
                          `has-background-${documentNLPMixin.getClassificationStatusColor(classifier)} 
                          has-text-${documentNLPMixin.getClassificationStatusColor(classifier) === 'grey-dark' ? 'white' : 'black'} document-header-icon`,
                        ]"
                      />
                      <span
                        v-if="match.sentence"
                        data-cy="document-header-sentence"
                        style="font-style: italic"
                        @click.stop="scrollToSentence(doc)"
                      >
                        {{ match.sentence }}
                      </span>
                    </template>
                  </div>
                </template>
                <div v-else data-cy="document-header-classification-not-classified">
                  <n-icon :icon="['fal', documentNLPMixin.getClassificationLabelIcon(doc)]" />
                  {{ doc.ClassificationStatusLabel }}
                </div>
              </div>
            </div>
            <div class="column is-3 header-buttons-column" style="text-align: center">
              <div @click="handleClick">
                <a-radio-group
                  v-if="view !== 'probe'"
                  v-model:value="documentStore.selectedDocumentPanelView"
                  button-style="solid"
                  size="small"
                  :default-value="getDefaultView(doc)"
                >
                  <a-radio-button v-if="showOriginalView(doc)" :value="'text'">
                    {{ 'Text' }}
                  </a-radio-button>
                  <a-radio-button :value="'pdf'">
                    {{ 'Original' }}
                  </a-radio-button>
                </a-radio-group>
                <div v-if="view === 'probe'">
                  <n-tooltip :text="$t('Classifier Source')" placement="bottom">
                    <a-radio-group
                      v-if="view === 'probe'"
                      data-cy="probe-document-switch"
                      v-model:value="uiStore.state.probeHighlighting"
                      button-style="solid"
                      @change="onSwitchChange"
                    >
                      <a-radio-button class="n-m-0 doc-btn doc-probe-btn" value="probe"
                        >Probe</a-radio-button
                      >
                      <a-radio-button class="n-m-0 doc-btn" value="rova">Rova</a-radio-button>
                      <a-radio-button class="n-m-0 doc-btn doc-regex-btn" value="regex"
                        >Regex</a-radio-button
                      >
                    </a-radio-group>
                  </n-tooltip>
                </div>
              </div>
            </div>
          </div>
        </template>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script setup>
import { find } from 'lodash'
import DocumentReader from '@/components/document-nlp/document-reader/document-reader.vue'
import { getFriendlyDate } from '@/mixins/n-time'
import NIcon from '@/components/ui/n-icon/n-icon.vue'
import NTag from '@/components/ui/n-tag/n-tag.vue'
import useDocumentStore from '@/store/modules/document'
import useUiStore from '@/store/modules/ui'
import { ref, watch, onBeforeUnmount } from 'vue'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import { CaretRightOutlined } from '@ant-design/icons-vue'
import NTooltip from '@/components/ui/n-tooltips/n-tooltip.vue'

const documentNLPMixin = useDocumentNLPMixin()
const documentStore = useDocumentStore()
const uiStore = useUiStore()

const activeKey = ref(['0'])
const enabledTags = ref(['Date', 'Specialty'])

defineProps({
  view: {
    type: String,
    required: false,
    default: '',
  },
})

const getSourceDocumentId = (doc) => {
  if (doc.RovaModel?.knownFacts?.SourceDocumentID) {
    return doc.RovaModel?.knownFacts?.SourceDocumentID
  }
  return doc.knownFacts?.SourceDocumentID
}

const scrollToSentence = (doc) => {
  const positiveClassifiers = doc.classifications.filter((c) => c.result !== 'negative')
  document
    .getElementById(`sentence-icon-classification-id-${positiveClassifiers[0]._id}`)
    .scrollIntoView({
      behavior: 'smooth',
    })
}

const showOriginalView = (doc) => {
  return !(doc.error === '404' || doc.TextContent === null)
}

const getDefaultView = (doc) => {
  if (doc.error === '404' || doc.TextContent === null) {
    return 'iframe'
  } else if (doc.TextContent && doc.DefaultViewer === 'original') {
    return 'iframe'
  }

  return documentStore.selectedDocumentPanelView
}

const getClassificationGroups = (classifications) => {
  const groups = []

  for (const classification of classifications) {
    for (const match of classification.matches) {
      const matchedGroup = find(groups, { sentence: match.sentence })

      // If no match exists, create the group
      if (!matchedGroup) {
        groups.push({
          sentence: match.sentence,
          result: classification.result,
          classifiers: [classification.classifier],
          hideInDocumentReader: classification.hideInDocumentReader,
        })
      } else {
        // If a match exists, handle the additional classifier
        matchedGroup.classifiers.push(classification.classifier)
      }
    }
  }
  return groups
}

const getKey = (index) => {
  // See: #922 this starts index at 0 for a single doc and at 1 for multiple docs
  // as the activeKey is initialised to ['0'] this results in:
  // (a) Document expanded if a single doc is loaded into Docs panel
  // (b) Documents collapsed if multiple docs loaded.
  return documentStore.documentModels.length === 1 ? index.toString() : (index + 1).toString()
}

const handleClick = (event) => {
  // If you don't want click extra trigger collapse, you can prevent this:
  event.stopPropagation()
}

const getRovaVersion = (doc) => {
  if (!doc.RovaVersion) {
    return null
  }
  return `v${doc.RovaVersion}`
}

const onSwitchChange = (event) => {
  documentStore.documentModels[0]?.classifications?.forEach((c) => {
    c.hideInDocumentReader = c.source === event.target.value ? false : true
  })
}

watch(
  () => documentStore.documentModels,
  (doc) => {
    if (doc) {
      onSwitchChange({ target: { value: uiStore.state.probeHighlighting } })
    }
  },
)

onBeforeUnmount(() => {
  uiStore.state.probeHighlighting = 'rova'
})
</script>

<style lang="less">
.document-accordion {
  background: #fdfdfd;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden !important;

  & .ant-collapse-extra {
    display: block !important;
    flex: 12 !important;
  }
  & .ant-collapse-content-box {
    padding-top: 16px !important;
  }
  & .ant-collapse-content {
    color: black;
  }
  & .ant-collapse-header {
    width: 100% !important;
    background: #f7f7f7 !important;
  }
}
.document-tag-selector {
  background: rgba(241, 241, 241, 0.9);
  padding: 0.35rem 0.75rem 0.35rem;
  margin-bottom: 0.5rem;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 5px;
}
.document-item {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px dashed black;
  &:last-child {
    border-bottom: none;
  }
}
.header-buttons-column {
  padding: 15px 10px 0px 30px !important;
}
.header-tags-column {
  padding: 0px 12px 12px 12px !important;
}
.document-accordion {
  overflow-y: scroll;
}
.document-panel {
  overflow-y: scroll;
  height: 70vh;
}
.ant-collapse-expand-icon {
  align-self: center !important;
  padding-right: 10px !important;
}
.document-panel .ant-collapse-header-text {
  max-width: 0px !important;
}
.document-header-icon {
  border-radius: 3px;
  & .fa-icon {
    padding: 2px;
  }
}
</style>
<style scoped>
.mr-1 {
  padding: 2px !important;
}
.highlighting-switch {
  margin-bottom: 10px;
}
.doc-btn {
  width: 70px;
  border-radius: 0px;
  display: grid;
  place-items: center;
  border-inline-start-width: 1px;
}
.doc-probe-btn {
  border-bottom: 0px;
}
.doc-regex-btn {
  border-top: 0px;
}
</style>
<style>
.document-header-icon .fa-ban {
  font-size: 0.83em;
}
</style>
