export default {
  'en-gb': {
    hello: 'Translations are working!',
    MissingPatientBar: 'No Patient Selected',
    patient: 'Patient',
    pathway: 'Pathway',
    Born: 'Born',
    'Patient Name': 'Patient Name',
    Gender: 'Gender',
    Age: 'Age',
    MRN: 'Hospital Number',
    'NHS No.': 'NHS No.',
    Deceased: 'Deceased',
    ascending: 'Ascending',
    descending: 'Descending',
    'no-items-found': 'No items found',
    detail: 'Inspector',
    classifications: 'Rova Classifications',
    'validation-history': 'Validation History',
    documents: 'Documents',
    tags: 'Tags',
    sentences: 'Sentences',
    metadata: 'Metadata',
    'activity-tooltip-help':
      '<h3>Patient Summary</h3><p>Use this panel for overview of patient activities and documents:</p><ul><li><strong>Activities</strong> View a grid of many activities and their high-level attributes. The grid can be easily sorted, filtered and grouped.</li><li><strong>Rova Classifications</strong> View a grid of documents, their high-level attributes and any available Rova Classifications. The grid may be sorted, filtered and grouped.</li></ul><p>Clicking any activity or document in this panel will also highlight the corresponding activity/document in the timeline panel.</p><p>Use the dropdown menu to choose the activity date sort direction</p><p>Dealing with a complex pathway? Use the filters at the top of Surveyor to hone in on the key data and/or click the full-screen icon to maximise your view',
    'probe-grid-help':
      '<h3><strong>Matched documents</strong></h3><p>Use this panel for overview of matched documents.</p></br><p>The grid will update based on compromise input</p></br><p>Use the <strong>switches</strong> to selectively display regex, compromise (probe), Rova, document, and pathway data.</p></br><p>Clicking any activity or document in this panel will display the corresponding document in the document viewer.</p>',
    'probe-document-help':
      '<h3><strong>Document Viewer</strong></h3><p>Use this panel for a detailed view of the document selected in the grid.</p><br><p>Use the <strong>buttons</strong> to switch between Probe (compromise), Rova, and Regex matches</p>',

    'vis-timeline-tooltip-help':
      '<h3>Timeline</h3> <p>Use the timeline for an overview of a complete patient/pathway:</p> <ul> <li>Click on an activity to load the activity</li> <li>A loaded activity displays the activity data, detail, documents and more in the panels below</li> <li>Pan the timeline using Click+Drag</li> <li>Zoom the timeline using Shift+Scroll</li> <li>Use the controls above the timeline to group activities</li> <li>Use the toggles to add/remove timeline swimlanes</li> <li>Dealing with a complex pathway? Use the full-screen icon to maximise your view</li> </ul>',
    'viewer-tooltip-help':
      '<h3>Viewer</h3> <p>Use this panel for more detailed inspection of the Pathway.<ul><li><strong>Inspector:</strong> Detailed data attributes inspection of the currently selected activity or document</li><li><strong>Documents:</strong> View the currently selected document or multiple documents for a selected Patient Activity</li><li><strong>Tags:</strong> View any document tags found by Rova</li><li><strong>Sentences:</strong> A grid of sentences found in the documents</li><li><strong>Metadata:</strong> View any meta data found by Rova</li>',
    grid: 'Activities',
    activities: 'Activities',
    records: 'Pathways',
    cards: 'Cards',
    sort: 'Sort',
    'search-activities': 'Search activities',
    visitDate: 'Visit Date',
    dictatedDate: 'Dictated Date',
    createdDate: 'Created Date',
    casualtyDate: 'Casualty Date',
    injuryDate: 'Injury Date',
    fileDate: 'Created Date',
    unknown: 'Unknown Date',
    Unknown: 'Unknown Date',
    modifiedDate: 'Modified Date',
    procedureDate: 'Procedure Date',
    clinicDate: 'Visit Date',
    typedDate: 'Typed Date',
    approvalDate: 'Approval Date',
    approvedDate: 'Approval Date',
    sentDate: 'Sent Date',
    publishedDate: 'Published Date',
    'no-documents-loaded': 'No Documents Loaded',
    master_document_unlinked_files_warning:
      'Unlinked documents will not be automatically loaded into the timeline.',
    master_document_selection_change_warning:
      'Warning: adding or removing patient records in the Master Patient Records Table will reset your selections in the Master Documents Table.',
    unsupported_document_header: 'Unsupported Media ${fileExtension}',
    unsupported_document_body:
      'Viewing of original ${fileExtension} files is not supported yet. Only PDF, HTML and TXT is supported at this time.',
    ViewDescription: 'View',
    CohortID: 'Cohort',
    'Week Band': 'Week Band',
    Status: 'Status',
    'Search By ID': 'Search By ID',
    'Search By': 'Search By',
    'Patient Information': 'Patient Information (PID)',
    'Patient Pathways Table': 'Patient Pathways Table',
    Timeline: 'Timeline',
    'Validate Pathway': 'Validate Pathway',
    'Master Document Table': 'Master Document Table',
    Legend: 'Legend',
    'Patient Pathways': 'Patient Pathways',
    Filters: 'Filters',
    Refresh: 'Refresh',
    Show: 'Show',
    Hide: 'Hide',
    'Regular View': 'Regular View',
    'Full-Screen Mode': 'Full-Screen Mode',
    'Add a Filter': 'Add a Filter',
  },
}
