import { defineStore } from 'pinia'
import { activityService } from '@/services/endpoints/activity'
import { ref } from 'vue'

export default defineStore('activity', () => {
  const selection = ref(null)
  const details = ref({})

  const clearActivityData = () => {
    details.value = {}
    selection.value = null
  }

  const loadDetails = async (query) => {
    const data = await activityService.getDetails(query)

    if (!data) return

    details.value = data
  }

  return {
    selection,
    details,
    loadDetails,
    clearActivityData,
  }
})
