import Fuse from 'fuse.js'
import { generateSearchQuery, queryByParams } from '@/helpers/searchjs-filters.js'
import _ from 'lodash'
import useFilterStore from '@/store/modules/filter'
import useTimelineStore from '@/store/modules/timeline'
import useValidationStore from '@/store/modules/validation.js'
import { computed, reactive, ref } from 'vue'
import useAbility from '@/services/ability'
import useDocumentStore from '@/store/modules/document'
import useUiStore from '@/store/modules/ui'
import useActivityStore from '@/store/modules/activity'
import { DataSet } from 'vis-timeline/standalone'
import dayjs from 'dayjs'
import { useRouter, useRoute } from 'vue-router'
import useConfigStore from '@/store/modules/config'
import { source } from '@/services/http'

export default () => {
  const filterStore = useFilterStore()
  const timelineStore = useTimelineStore()
  const validationStore = useValidationStore()
  const documentStore = useDocumentStore()
  const uiStore = useUiStore()
  const activityStore = useActivityStore()
  const configStore = useConfigStore()
  const ability = useAbility()
  const router = useRouter()
  const route = useRoute()

  // config,
  const sortModeOptions = ref([
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
  ])
  const sortMode = ref('desc')
  const fuseOptions = reactive({
    includeScore: true,
    threshold: 0.1,
    isCaseSensitive: false,
    ignoreLocation: true,
    keys: [
      'PathwayID',
      'Specialty',
      'Division',
      'Site',
      'ActivityID',
      'Consultant',
      'TreatmentFunction',
      'ActivityType',
      'ActivityCategory',
      'ActivityStatus',
      'ActivityDateLabel',
      'SystemUser',
    ],
  })

  const currentIDs = timelineStore.currentIDs

  const filteredDocumentList = computed(() => {
    // Remove the ClassificationId from documents as there are multiple classifications per document
    // TODO move this into the API layer
    let documents = documentStore.documents.map((d) => ({
      ...d,
      ClassificationId: null,
    }))
    const queryParams = generateSearchQuery(filterStore.segment)

    if (queryParams && queryParams.classification) {
      documents = queryByParams(documents, queryParams.classification)
    }
    return documents
  })

  const filteredActivityList = computed(() => {
    let filters = filterStore.segment
    let result = timelineStore.timeline

    // Fuzzy search the json if a activitySearchInput is supplied
    if (timelineStore.activitySearchInput) {
      let fuse = new Fuse(result, fuseOptions)

      result = fuse.search(timelineStore.activitySearchInput || '')

      if (result.length > 0) {
        result = _.map(result, 'item')
      }
    }

    // If a valid date range is selected, filter between these dates
    if (timelineStore.dateRangeFilter?.length > 1) {
      // Pull the moment objects from the ant date range
      let startDate = timelineStore.dateRangeFilter[0].toDate() || null,
        endDate = timelineStore.dateRangeFilter[1].toDate() || null

      result = result.filter((a) => {
        const hitDates = dayjs(a.ActivityDate, 'YYYY-MM-DD').toDate() || {}

        return hitDates >= startDate && hitDates <= endDate
      })
    }

    const queryParams = generateSearchQuery(filters)

    if (queryParams && queryParams.activity) {
      result = queryByParams(result, queryParams.activity)
    }

    return result
  })

  const currentSelections = computed(() => {
    if (documentStore.selectedFileNumbers.length > 0) {
      return documentStore.selectedFileNumbers
    }
    return documentStore.classificationList.map((elm) => elm.FileNumber)
  })

  const clearTimelineItems = () => {
    // Cancel any pending requests
    source.cancel('PTLUniqueID change, cancelling requests.')

    timelineStore.visTimeline?.setData({ items: new DataSet([]) })
    documentStore.clearDocumentData()
    timelineStore.clearTimelineData()
    validationStore.clearValidationData()
    activityStore.clearActivityData()
  }

  const setPTLUniqueId = async (PTLUniqueIDs) => {
    window.parent.postMessage({ name: 'surveyor-data-loading' }, '*')

    // Reset the timeline data when the PTLUniqueID changes to prevent data from previous pathways being displayed
    clearTimelineItems()

    currentIDs.PTLUniqueID = PTLUniqueIDs

    timelineStore.timelineFilterChanged = false

    // Refresh data when PTLUniqueID changes
    // Wait for timeline to load before refreshing documents
    // Otherwise the unlinked document in query string is not highlighted, required for #17473
    await timelineStore.preloadTimeline(currentIDs.PTLUniqueID)

    timelineStore.fetchPathwayInfo()
    timelineStore.fetchCensusDate()
    timelineStore.fetchRelatedPathways()
    await refreshDocuments()
    window.parent.postMessage({ name: 'surveyor-data-loaded' }, '*')

    if (PTLUniqueIDs.length > 1) {
      uiStore.updateUserDefaults('PathwayID', 'visTimelineGroupMode')
    }
    if (PTLUniqueIDs.length === 1) {
      uiStore.updateUserDefaults('none', 'visTimelineGroupMode')
    }
  }

  const setDemoData = () => {
    setPTLUniqueId([['681633189040527370']])
    timelineStore.initialPTLUniqueID = '681633189040527370'
    configStore.appParams.PTLUniqueID = '681633189040527370'
    currentIDs.CohortCode = 'RTTPTL01'
    currentIDs.CohortID = '1'

    // Set the PTLUniqueID in the route query params
    router.push({
      query: {
        ...configStore.appParams,
        PTLUniqueID: '681633189040527370',
        CohortCode: 'RTTPTL01',
        CohortID: '1',
        User: 'demo',
      },
    })
  }

  const fetchStartupData = () => {
    const PTLUniqueIDs =
      configStore.appParams.ParentPTLUniqueID || configStore.appParams.PTLUniqueID
    const FileId = configStore.appParams.FileId

    // Redirect to Document Viewer if FileNumber is supplied without PTLUniqueID
    if (!PTLUniqueIDs && FileId) {
      return router.push({ name: 'document-viewer', query: configStore.appParams })
    }

    let puids = []
    // Set demo data if no PTLUniqueID supplied
    if (!PTLUniqueIDs && ability.can('view', 'activity:demo-scenarios') && !route.query.embedded) {
      setDemoData()
      return
    }

    if (_.isEmpty(PTLUniqueIDs)) {
      return
    }

    if (PTLUniqueIDs.includes('|')) {
      puids = PTLUniqueIDs.split('|').map((puid) => [puid])
    } else {
      puids = [PTLUniqueIDs]
    }

    timelineStore.initialPTLUniqueID = puids[0]
    setPTLUniqueId([puids])
  }

  const handlePTLUniqueIDChange = () => {
    timelineStore.handlePTLUniqueIDChange()
  }

  const refreshDocuments = async () => {
    await documentStore.queryDocuments({
      tblPTLUniqueID: currentIDs.PTLUniqueID,
      tblFileNumber: null,
      linkedFilesOnly: 0,
    })
    documentStore.updateClassifications()
  }

  const resetSearchFilters = () => {
    timelineStore.dateRangeFilter = []
    timelineStore.activitySearchInput = ''
  }

  const filterDocuments = (data) => {
    // if (this.showCurrentDocumentsOnly){
    return data.filter((item) => {
      // Return documents which have a PTLUniqueID matching the currently selected ones or where PTLUID is null
      const selectedItems = currentIDs.PTLUniqueID.filter((row) => {
        return _.includes(
          row.map((elm) => toString(elm)),
          toString(item.PTLUniqueID),
        )
      })

      return !_.isEmpty(selectedItems) || !item.PTLUniqueID
    })
    // }
    // return data;
  }

  // set a unique row node id
  const getRowNodeId = (r) => {
    return `${r.FileNumber}${r.ClassificationId}`
  }

  return {
    sortModeOptions,
    sortMode,
    fuseOptions,
    filterStore,
    timelineStore,
    validationStore,
    currentIDs,
    filteredDocumentList,
    filteredActivityList,
    currentSelections,
    setPTLUniqueId,
    setDemoData,
    fetchStartupData,
    handlePTLUniqueIDChange,
    resetSearchFilters,
    filterDocuments,
    getRowNodeId,
  }
}
