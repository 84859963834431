<template>
  <div
    class="card panel"
    :class="{ 'gx-cb-hover': hasHover, 'gx-cb--loading': loading, fullscreen: fullscreen }"
  >
    <div class="gx-cb--body" :class="[`gx-p-${bodyPadding}`]">
      <a-row v-if="showHeader" type="flex">
        <a-col class="p-3">
          <tippy theme="light left">
            <template #content>
              <div class="card">
                <div class="card-content">
                  <slot name="help" />
                </div>
              </div>
            </template>
            <i class="fal fa-question-circle" :style="{ fontSize: '22px', lineHeight: '40px' }" />
          </tippy>
        </a-col>
        <a-col flex="1 1">
          <slot name="actions" />
        </a-col>
        <a-col>
          <slot name="actions-mid" />
        </a-col>
        <a-col class="action-slots p-2">
          <slot name="actions-right" />
          <n-tooltip :text="isCollapsed ? $t('Show') : $t('Hide')" placement="top">
            <a-button shape="square" @click="toggleCollapse">
              <template #icon>
                <DownOutlined v-if="isCollapsed" />
                <UpOutlined v-else />
              </template>
            </a-button>
          </n-tooltip>
          <!--Temp commented out due to tooltip bug when resizing panels-->
          <n-tooltip
            :text="fullscreen ? $t('Regular View') : $t('Full-Screen Mode')"
            placement="top"
          >
            <a-button
              data-cy="full-screen-button"
              ref="fullScreenButton"
              shape="square"
              @click="toggleFullscreen"
            >
              <template #icon>
                <ShrinkOutlined v-if="fullscreen" />
                <ArrowsAltOutlined v-else />
              </template>
            </a-button>
          </n-tooltip>
        </a-col>
      </a-row>
      <div class="panel-content mx-4" :class="{ collapsed: isCollapsed }">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script setup>
import NTooltip from '@/components/ui/n-tooltips/n-tooltip.vue'
import useUIStore from '@/store/modules/ui'
import { ref, nextTick } from 'vue'
import { Tippy } from 'vue-tippy'
import { DownOutlined, UpOutlined, ShrinkOutlined, ArrowsAltOutlined } from '@ant-design/icons-vue'
import useAgGridStore from '@/store/modules/agGrid'
// import { set } from 'cypress/types/lodash'

// name: 'NPanel',
const props = defineProps({
  showHeader: {
    type: Boolean,
    default: true,
  },
  hasHover: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  bodyPadding: {
    type: Number,
    default: 3,
    required: false,
  },
  help: {
    type: String,
    required: false,
    default: '',
  },
  selectedTab: {
    type: String,
    required: false,
    default: '',
  },
})
const uiStore = useUIStore()
const agGridStore = useAgGridStore()
const fullscreen = ref(false)
const isCollapsed = ref(false)
const fullScreenButton = ref()

const resizeAgGrid = () => {
  agGridStore.agGrids[props.selectedTab]?.api?.sizeColumnsToFit()
}

const toggleFullscreen = async () => {
  // Needed to prevent tooltip from staying open -
  // Sometimes the mouseleave event doesn't fire before the panel changes size
  fullScreenButton.value.$el.dispatchEvent(new MouseEvent('mouseleave'))

  fullscreen.value = !fullscreen.value
  await nextTick()
  uiStore.state.isFullscreen = fullscreen.value
  resizeAgGrid()
  if (isCollapsed.value && fullscreen.value) {
    toggleCollapse()
  }
}

const toggleCollapse = () => {
  fullScreenButton.value.$el.dispatchEvent(new MouseEvent('mouseleave'))
  isCollapsed.value = !isCollapsed.value
  if (fullscreen.value && isCollapsed.value) {
    toggleFullscreen()
  }
}
</script>

<style lang="less">
/* @todo goran - this seems v. buggy in IE. Must be a better way. Use JS? Use Flex? */
.panel {
  box-shadow: 1px !important;
  &.fullscreen {
    display: block;
    z-index: 1000;
    position: fixed;
    width: calc(100% - 80px);
    height: calc(100% - 48px);
    right: 0;
    left: 80px;
    bottom: 0;
    margin: 0;
    & .panel-content {
      height: 100%;
    }
  }
  & .gx-cb--body {
    height: 100%;
    position: relative;
  }
  & .action-slots button:last-child {
    margin-right: 0;
  }
  & .action-slots button {
    margin-right: 0.5rem;
  }
  & .panel-content.collapsed {
    display: none;
  }
}

.tippy-tooltip.left-theme {
  text-align: left;
}
.tippy-tooltip {
  background-color: white !important;
}

@media screen and (max-width: 1004px) {
  .panel.fullscreen {
    height: calc(100% - 70px);
  }
}

@media screen and (max-width: 518px) {
  .panel.fullscreen {
    height: calc(100% - 94px);
  }
}

@media screen and (max-width: 410px) {
  .panel.fullscreen {
    height: calc(100% - 118px);
  }
}
</style>
<style scoped>
button {
  margin: 4px !important;
}
</style>
