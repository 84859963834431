// import Vue from 'vue'
import { getNIcon, getVisTimelineRttTooltip } from '@/helpers/component-loader.js'
import useAbility from '@/services/ability.js'
// import { AbilityTypes } from '@/store/modules/ability/ability-actions'
import _ from 'lodash'
import useDocumentStore from '@/store/modules/document'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import useActivityMixin from '@/mixins/activity-mixin'
import useDocumentMixin from '@/mixins/documentNLPMixin.js'
import { getFriendlyDate, getFriendlyDateTime } from '@/mixins/n-time.js'
import dayjs from 'dayjs'

export default () => {
  const settings = useActivityMixin()
  const ability = useAbility()
  const documentStore = useDocumentStore()
  const documentNLPMixin = useDocumentNLPMixin()

  const getRTTTooltipContent = (data) => {
    let items = []

    if (data.activityType) {
      items = [
        { title: 'Date start', type: Date, value: data.start },
        { title: 'Date end', type: Date, value: data.end },
        { title: 'Activity Description', type: String, value: data.activityDescription },
        { title: 'Local Code', type: String, value: data.statusLocalLong },
        {
          title: 'National Code Group',
          type: String,
          value: settings.getRTTNationalDescriptions(data.statusLocal)
            .RTTGroupDescriptionLongNational,
        },
        {
          title: 'National Code Description',
          type: String,
          value: `${data.statusNational} ${
            settings.getRTTNationalDescriptions(data.statusNational).RTTDescriptionLongNational
          }`,
        },
      ]
    } else {
      items = [
        { title: 'Status', type: String, value: data.status },
        { title: 'Start Date', type: Date, value: data.start },
        {
          title: 'End Date',
          type: Date,
          value: ['open', 'active', 'running'].includes(data.status.toLowerCase())
            ? null
            : data.end,
        },
        { title: 'Duration (Days)', type: String, value: `${data.duration} days` },
        {
          title: 'Duration (Weeks)',
          type: String,
          value: `${Math.floor(data.duration / 7)} weeks`,
        },
        {
          title: 'Duration (Total)',
          type: String,
          value:
            data.duration % 7 === 0
              ? `${Math.floor(data.duration / 7)} weeks`
              : `${Math.floor(data.duration / 7)} weeks ${data.duration % 7} days`,
        },
      ]
    }

    return getVisTimelineRttTooltip(items)
  }
  const getRovaTooltipContent = (data) => {
    const documentMixin = useDocumentMixin()
    // TODO: sort out Ability Types etc
    // const rules = this.$store.getters[AbilityTypes.getters.GET_RULES]
    let message = null

    const items = [
      { title: 'Type', type: String, value: data.LetterType },
      { title: 'Rova Status', type: String, value: data.ClassificationStatusLabel },
      { title: 'Classifier', type: String, value: data.ClassifierLabel },
      {
        title: 'Explanation',
        type: String,
        value: documentMixin.getClassifier(data.Classifier).explanation,
      },
      {
        title: data.DateTag,
        type: String,
        value: getFriendlyDate(data.FileDate),
      },
      {
        title: 'Processed On',
        type: String,
        value: getFriendlyDateTime(data.ProcessedOn),
      },
      { title: 'Sentence', type: String, value: data.Sentence },
      { title: 'Specialty Code', type: String, value: data.ActivitySpecialty },
      { title: 'Linked To', type: String, value: data.LinkedTo },
      { title: 'Linked To ID', type: String, value: data.LinkedToID },
      { title: 'Document ID', type: String, value: data.SourceDocumentID },
      { title: 'Doc Category', type: String, value: data.DocumentCategory },
      { title: 'Doc Title', type: String, value: data.DocumentTitle },
    ]

    if (data.Classifier && !(data.Classifier?.toLowerCase() in documentMixin.classifiers)) {
      message =
        'Surveyor detected a new classifier from Rova that has not yet been configured with an icon / colour in Surveyor. Please contact LUNA Support to enable this configuration.'
    }

    // if (rules.find((r) => r.actions === 'view' && r.subject === 'context:pattern')) {
    if (ability.can('view', 'context:pattern')) {
      items.push({ title: 'Phrase', type: String, value: data.Phrase })
    }

    if (ability.can('view', 'document:confidence')) {
      items.push({
        title: 'Confidence Level',
        type: String,
        value: documentStore.getConfidenceLevel(data.Classifier),
      })
    }

    return getVisTimelineRttTooltip(items, message)
  }

  const getRovaGroupTooltipContent = (data) => {
    const items = []
    data.forEach((item) => {
      items.push({
        colour: documentNLPMixin.getClassificationStatusColor(item.Classifier),
        title: item.ClassifierLabel || 'No Classification',
        type: String,
        value: dayjs(item.FileDate).format('DD/MM/YYYY'),
      })
    })
    return getVisTimelineRttTooltip(items, '')
  }

  const getValidationTooltipContent = (data) => {
    const items = Object.entries(data).map(([key, value]) => {
      return {
        title: key
          .replace(/([A-Z][a-z])/g, ' $1')
          .replace(/(\d)/g, ' $1')
          .trim(),
        type: key === 'ValidatedOn' ? 'DateTime' : String,
        value: value ? _.capitalize(value) : null,
      }
    })

    // Put validation target first in the tooltip
    const ValidationTarget = items.find((item) => item.title === 'Validation Target')
    const organisedItems = items.filter((item) => item.title !== 'Validation Target')
    organisedItems.unshift(ValidationTarget)

    return getVisTimelineRttTooltip(organisedItems)
  }
  const getActivityTooltipContent = (data) => {
    const activityIcon = getNIcon({
      type: 'fal',
      icon: settings.getActivityIcon(data.ActivityCategory),
    })

    const activityStatusIcon = getNIcon({
      type: 'fal',
      icon: settings.getStatusIcon(data.ActivityStatus),
      cssClass: `has-text-${settings.getStatusColor(data.ActivityStatus)}`,
    })

    const items = [
      { title: data.ActivityDateLabel, type: Date, value: data.ActivityDate },
      {
        title: 'Activity Type',
        type: String,
        value: `${activityIcon.innerHTML} ${data.ActivityType}`,
      },
      {
        title: 'Activity Status',
        type: String,
        value: `${activityStatusIcon.innerHTML} ${
          data.ActivityStatus ? data.ActivityStatus : 'N/A'
        }`,
      },
      {
        title: 'Activity Subtype',
        type: String,
        value: data.ActivitySubType ? data.ActivitySubType : 'N/A',
      },
      { title: data.ActivityIDType, type: String, value: data.ActivityID },
      { title: 'Specialty', type: String, value: data.Specialty },
      { title: 'Treatment Function', type: String, value: data.TreatmentFunction },
      { title: 'Consultant', type: String, value: data.Consultant },
      { title: 'Pathway ID', type: String, value: data.PathwayID },
      { title: 'Sites', type: String, value: data.Site },
      { title: 'Illogical Severity', type: String, value: data.illogicalSeverity },
      {
        title: 'Illogical Reason',
        type: String,
        value: data.illogicalReason,
        class: `ilg-severity-${data.illogicalSeverity}-color`,
      },
    ]

    return getVisTimelineRttTooltip(items)
  }

  return {
    getRTTTooltipContent,
    getRovaTooltipContent,
    getRovaGroupTooltipContent,
    getValidationTooltipContent,
    getActivityTooltipContent,
  }
}
