<template>
  <span>
    <document-validation-context-menu
      ref="validationContextMenu"
      data-cy="classification-validation-modal"
      :classification-data="classificationData"
      :validation-mode="validationMode"
    />
    <a-popover
      v-model:open="popoverVisible"
      placement="left"
      class="document-tags px-2"
      @visibleChange="refreshValidationHistory"
    >
      <template #title
        ><h2 data-cy="classification-popover-classifier-title">{{ classification.label }}</h2>
        <n-tag
          data-cy="classification-popover-confidence-level-tag"
          class="popover-confidence-level-tag"
          label="Confidence Level"
          :value="getConfidenceLevel(classification.classifier)"
        />
        <hr />
      </template>
      <template v-if="ability.can('view', 'context:validation')" #content>
        <a-row data-cy="classification-popover-content" class="mb-2">
          <a-col v-if="classifier.explanation" :span="24" class="mb-2">
            <div class="subtitle mb-1">Description</div>
            <p>{{ classifier.explanation }}</p>
          </a-col>
          <a-col v-if="validationAllowed" :span="24" class="mb-2">
            <div class="subtitle mb-1">Validate</div>
            <a-button
              data-cy="classification-Approve-button"
              size="large"
              class="mr-3"
              @click="handleClassificationApproval"
            >
              <span class="validate-label">Approve</span>
              <n-icon :icon="['fal', 'check']" />
            </a-button>
            <a-button
              data-cy="classification-Reject-button"
              size="large"
              class="mr-3"
              @click="openValidationModal('reject')"
            >
              <span class="validate-label">Reject</span>
              <n-icon :icon="['fal', 'times']" />
            </a-button>
            <a-button
              data-cy="classification-Change-button"
              size="large"
              class="mr-3"
              @click="openValidationModal('change')"
            >
              <span class="validate-label">Change</span>
              <n-icon :icon="['fal', 'pencil']" />
            </a-button>
          </a-col>
        </a-row>
        <a-row class="mb-2">
          <a-col :span="24"><div class="subtitle mb-1">Validation History</div></a-col>
          <a-col :span="24">
            <a-table
              data-cy="validation-history-table"
              :data-source="validationHistory"
              :columns="validationColumns"
              :pagination="false"
              :scroll="{ x: 500, y: 500 }"
            >
              <template #expandedRowRender="{ record }">
                <p
                  data-cy="classification-popover-expanded-row"
                  style="margin: 0"
                  v-html="record.ValidationComment"
                />
              </template>
            </a-table>
          </a-col>
        </a-row>
      </template>
      <!-- Underline -->
      <mark
        v-if="type === 'tag'"
        :data-entity="tag"
        :style="{ 'background-color': backgroundColor }"
      />
      <!-- Icon -->
      <a-button
        class="n-p-0 n-mb-0"
        :class="[
          `has-border-${classifier.colour === 'white' ? 'grey-dark' : classifier.colour}`,
          `has-background-${classifier.colour}`,
        ]"
      >
        <n-icon
          v-if="classifier.icon"
          data-cy="classification-popover-icon"
          :icon="['fal', classifier.icon]"
          class="n-ml-05 n-mr-05"
          :class="`has-text-${classifier.colour === 'grey-dark' ? 'white' : 'black'}`"
        />
      </a-button>
    </a-popover>
  </span>
</template>

<script setup>
import NIcon from '@/components/ui/n-icon/n-icon.vue'
import DocumentValidationContextMenu from '@/components/document-nlp/document-validation-context-menu/document-validation-context-menu.vue'
import useValidationStore from '@/store/modules/validation'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import useDocumentStore from '@/store/modules/document'
import NTag from '@/components/ui/n-tag/n-tag.vue'
import { computed, defineProps, ref, onBeforeMount, onMounted } from 'vue'
import useAbility from '@/services/ability.js'

const props = defineProps({
  classification: {
    type: Object,
    required: true,
  },
  fileId: {
    type: String,
    default: null,
  },
  fileNumber: {
    type: String,
    required: true,
    default: null,
  },
  tag: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
  backgroundColor: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    required: true,
  },
  rovaVersion: {
    type: String,
    default: null,
  },
})

const validationStore = useValidationStore()
const documentNLPMixin = useDocumentNLPMixin()
const ability = useAbility()
const validationContextMenu = ref(null)
const popoverVisible = ref(false)
const classificationData = ref(null)
const validationMode = ref(null)
const validationHistory = ref([])
const validationColumns = [
  {
    title: 'Date',
    dataIndex: 'ValidatedOn',
    key: 'ValidatedOn',
  },
  {
    title: 'User',
    dataIndex: 'ValidatedBy',
    key: 'ValidatedBy',
  },
  {
    title: 'Type',
    dataIndex: 'ValidationType',
    key: 'ValidationType',
  },
  {
    title: 'Rejection Reason',
    dataIndex: 'ValidationRejectionReason',
    key: 'ValidationRejectionReason',
  },
]
const classifier = ref(null)

const validationAllowed = computed(() => {
  if (classifier.value.active === false) {
    return false
  }

  return ability.can('viewAll', 'context:validation') || classifier.value.allowValidation
})

const classificationId = computed(() => {
  return props.classification._id
})

onBeforeMount(() => {
  classifier.value = documentNLPMixin.getClassifier(props.classification.classifier)
  classificationData.value = props
})

onMounted(() => {
  refreshValidationHistory()
})

const openValidationModal = (openMode) => {
  popoverVisible.value = false

  // HACKY FIX: Need to wait to allow popover to close
  setTimeout(() => {
    validationContextMenu.value.openModal(openMode)
  }, 200)
}

const handleClassificationApproval = async () => {
  await validationStore.approveValidation({
    FileId: props.fileId,
    FileNumber: props.fileNumber,
    RovaVersion: props.rovaVersion,
    ClassificationId: classificationId.value,
  })

  popoverVisible.value = false
}

const refreshValidationHistory = () => {
  validationHistory.value = validationStore.filterClassificationHistory(classificationId.value)
}

const getConfidenceLevel = (classifier) => {
  return useDocumentStore().getConfidenceLevel(classifier)
}
</script>

<style scoped>
p,
.ant-table {
  color: black;
}
.popover-confidence-level-tag {
  font-weight: 400 !important;
}
.validate-label {
  padding-right: 5px;
}
</style>
<style>
.ant-popover-content {
  width: 50vw !important;
}
</style>
