<script setup>
import { ref, onMounted } from 'vue'
import useFilterStore from '@/store/modules/filter'
import usei18nStore from '@/store/modules/i18n'
import useConfigStore from '@/store/modules/config'
import useUIStore from '@/store/modules/ui'
import { useRouter } from 'vue-router'

const router = useRouter()

const configStore = useConfigStore()
const filterStore = useFilterStore()
const i18nStore = usei18nStore()
const uiStore = useUIStore()

const initialised = ref(false)

const initialiseApp = () => {
  const calls = [
    configStore.fetchConfig(),
    i18nStore.fetchLocale('en'),
    uiStore.loadDefaultValues(),
    configStore.fetchPermissions(),
  ]

  Promise.all(calls)
    .then(() => {
      filterStore.overwriteFilterOptions(
        configStore.getConfigByKey('application-config.filtersList') || [],
      )
      filterStore.overwriteFilterGroups(
        configStore.getConfigByKey('application-config.filterGroups') || [],
      )

      initialised.value = true
    })
    .catch((error) => {
      console.error(error)
      router.push({ name: '401' })
      initialised.value = true
    })
}

/**
 * Embedded applications will receive a message from the parent application with the app params
 */
const handleEvent = (e) => {
  if (e.origin === window.location.origin) {
    return
  }

  if (e.data.name === 'surveyor-pathway-changed') {
    // Set the app params in the config store
    configStore.setAppParams(e.data.params)

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (!initialised.value) {
      initialiseApp()
    }
  }
}

// Register the event listener for embedded applications
window.addEventListener('message', handleEvent)

onMounted(() => {
  window.parent.postMessage({ name: 'surveyor-app-loaded' }, '*')
})

/**
 * If the application is not embedded, set the app params from the query string
 */
const urlParams = new URLSearchParams(window.location.search)
const appParams = {}
for (const [key, value] of urlParams.entries()) {
  appParams[key] = value
}
if (appParams.embedded !== 'true') {
  configStore.setAppParams(appParams)
  initialiseApp()
}

// const loading = ref(false)
</script>

<template>
  <div id="root" class="gx-app-layout ant-layout ant-layout-has-sider">
    <!-- Overrides the default colour for ant design -->
    <a-config-provider
      :theme="{
        token: {
          colorPrimary: '#072942',
        },
      }"
    >
      <router-view v-if="initialised" />
    </a-config-provider>
  </div>
</template>

<style>
body {
  margin: 0px;
  min-height: 100vh;
}
</style>
