<template>
  <ag-grid-vue
    data-cy="master-records-grid"
    style="width: 100%; height: 70vh"
    :modules="agGridMixin.agModules"
    class="ag-theme-balham master-records-table"
    :default-col-def="defaultColDef"
    :column-defs="columns"
    :side-bar="sidebar"
    :status-bar="statusPanels"
    :tools-panel="toolPanels"
    :animate-rows="true"
    :master-detail="true"
    :row-group-panel-show="'always'"
    :row-height="32"
    :row-data="timelineMixin.relatedPathways"
    :enable-cell-text-selection="false"
    :enable-browser-tooltips="true"
    :suppress-copy-rows-to-clipboard="true"
    :suppress-row-click-selection="true"
    row-selection="multiple"
    :pivot-panel-show="'always'"
    :framework-components="frameworkComponents"
    :enable-range-selection="true"
    :enable-charts="true"
    @grid-ready="onGridReady"
    :isRowSelectable="(params) => params.data?.PTLUniqueID !== timelineStore.initialPTLUniqueID"
    @selection-changed="handle($event)"
  />
</template>

<script setup>
import CheckboxCellRenderer from '../../helpers/checkbox-cell-renderer.js'
import { getDateComparator, getFriendlyDate } from '@/mixins/n-time'
import config from '@/config'
import useAgGridMixin from '@/mixins/agGridMixin.js'
import useAgGridStore from '@/store/modules/agGrid'
import useTimelineStore from '@/store/modules/timeline'
import defaultColDef from '@/mixins/globalDefaultColDef'
import { AgGridVue } from 'ag-grid-vue3'
import { ref, computed } from 'vue'
import useTimelineMixin from '@/mixins/timeline.mixin'
import { notification } from 'ant-design-vue'

const agGridMixin = useAgGridMixin()
const agGridStore = useAgGridStore()
const timelineMixin = useTimelineMixin()
const timelineStore = useTimelineStore()

const frameworkComponents = ref(null)
const statusPanels = ref(config.timeline.agTimelineDef.statusPanels)
const toolPanels = ref(config.timeline.agTimelineDef.toolPanels)
const sidebar = ref(config.timeline.agTimelineDef.sidebar)

const columns = ref([
  {
    headerName: '',
    field: 'selected',
    width: 40,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: (params) => {
      return params.data?.PTLUniqueID !== timelineStore.initialPTLUniqueID
    },
    showDisabledCheckboxes: false,
  },
  {
    headerName: 'PTLUniqueID',
    filter: 'agTextColumnFilter',
    field: 'PTLUniqueID',
    hide: true,
  },
  {
    headerName: 'ID Type',
    field: 'ID Type',
    hide: true,
    flex: true,
    width: 140,
    filter: 'agTextColumnFilter',
    sortable: true,
    editable: false,
  },
  {
    headerName: 'ID',
    field: 'ID',
    flex: true,
    filter: 'agTextColumnFilter',
    sortable: true,
    width: 160,
    editable: false,
  },
  {
    headerName: 'Record Type',
    field: 'Record Type',
    flex: true,
    width: 180,
    filter: 'agTextColumnFilter',
    sortable: true,
    editable: false,
  },
  {
    headerName: 'Start Date',
    field: 'Start Date',
    sort: 'desc',
    flex: true,
    width: 180,
    filter: 'agDateColumnFilter',
    sortable: true,
    editable: false,
    filterParams: {
      comparator: getDateComparator,
    },
    cellRenderer: (params) => {
      if (params.value) {
        return getFriendlyDate(params.value)
      }
      return null
    },
  },
  {
    headerName: 'Stop Date',
    field: 'Stop Date',
    flex: true,
    width: 180,

    filter: 'agDateColumnFilter',
    sortable: true,
    editable: false,
    filterParams: {
      comparator: getDateComparator,
    },
    cellRenderer: (params) => {
      if (params.value) {
        return getFriendlyDate(params.value)
      }
      return null
    },
  },
  {
    headerName: 'Clinician',
    field: 'Clinician',
    flex: true,
    minWidth: 100,
    filter: 'agTextColumnFilter',
    sortable: true,
    editable: false,
  },
  {
    headerName: 'TF/Specialty',
    field: 'Specialty',
    flex: true,
    minWidth: 100,
    filter: 'agTextColumnFilter',
    sortable: true,
    editable: false,
  },
])

const gridApi = ref() //(agGridStore.agGrids.masterRecord?.api)

const onGridReady = (params) => {
  agGridMixin.onGridReady(params, 'masterRecord')
  gridApi.value = params.api

  // Set the row data on tab change
  if (timelineStore.relatedPathways.length) {
    params.api.setRowData(timelineStore.relatedPathways)
    syncSelectedRows()
  }
}

timelineStore.$onAction(({ name, after }) => {
  if (name === 'fetchRelatedPathways') {
    after(() => {
      gridApi.value = agGridStore.agGrids.masterRecord?.api
      if (!gridApi.value) return

      gridApi.value.setRowData(timelineStore.relatedPathways)
      syncSelectedRows()
    })
  }
})

frameworkComponents.value = {
  checkboxRenderer: CheckboxCellRenderer,
}

const syncSelectedRows = () => {
  gridApi.value.forEachNode((node) => {
    node.setSelected(selectedRecords.value.includes(node.data.PTLUniqueID))
  })
}

const getSelections = () => {
  const data = gridApi.value.getSelectedNodes()

  return data.map((elm) => {
    return elm.data
  })
}
const selectedRecords = computed(() => {
  return timelineMixin.currentIDs.PTLUniqueID.map((item) => item[0])
})

const handle = (event) => {
  // Eliminates duplicate events on first selection, listens for header checkbox selection
  if (event.source !== 'checkboxSelected' && event.source !== 'uiSelectAllFiltered') return

  const data = getSelections()

  const PTLUniqueIDs = data.map((elm) => {
    return [elm.PTLUniqueID]
  })
  // Initial PTLUniqueID is always added to the list of selected PTLUniqueIDs
  PTLUniqueIDs.push([timelineStore.initialPTLUniqueID])

  // Reload all data for the selected pathways
  if (JSON.stringify(PTLUniqueIDs) !== JSON.stringify(timelineMixin.currentIDs.PTLUniqueID)) {
    timelineMixin.setPTLUniqueId(PTLUniqueIDs)
  }

  notification.success({
    message: 'Patient data has been updated.',
  })
}
</script>
<style lang="less">
// Override the background color of the ag-selected-row
.master-records-table {
  --ag-selected-row-background-color: #fff785 !important;
}
</style>
